<template>
  <div class="explore">
    <div class="explore__section">
      <div class="explore__section-container">
        <div class="explore__section-content">
          <h2 class="explore__section-heading">Présentation</h2>
          <p class="explore__section-text">
            Dans le but de promouvoir la transparence budgétaire et renforcer
            les relations entre les commissions parlementaires et la société
            civile, E-Tachawor est une plateforme dédiée à la diffusion de
            l’information budgétaire et le rapprochement entre les commissions
            parlementaires et la coalition marocaine pour la redevabilité, le
            but est de discuter et débattre des thématiques liées à la loi de
            finances de l’année durant tout le cycle budgétaire.
          </p>
        </div>
        <div class="explore__section-img-wrapper">
          <img
            class="explore__section-img"
            :src="require('@/assets/images/presentation.jpg')"
            alt=""
          />
          <!-- <img
            class="explore__section-img explore__section-img--small"
            src="https://images.unsplash.com/photo-1591696205602-2f950c417cb9?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=400&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTY0NzQ1MzE5Ng&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=400"
            alt=""
          /> -->
        </div>
      </div>
    </div>
    <div class="explore__section explore__section--swapped">
      <div class="explore__section-container">
        <div class="explore__section-content">
          <h2 class="explore__section-heading">Objectifs</h2>
          <ul class="explore__section-list">
            <li class="explore__section-list-item">
              La création d’un espace de rapprochement entre les commissions
              parlementaires et la coalition pour discuter et débattre des
              thématiques liée à la loi de finances à savoir son adoption et son
              exécution.
            </li>
            <li class="explore__section-list-item">
              Le renforcement des échanges directs entre les commissions
              parlementaires et les membres de la coalition (représentants de la
              société civile) sur la loi de finances, les décisions et les
              politiques publiques dressées par le gouvernement et les
              interventions de la commission.
            </li>
            <li class="explore__section-list-item">
              L’instauration d’un débat budgétaire participatif et inclusif.
            </li>
          </ul>
        </div>
        <div class="explore__section-img-wrapper">
          <img
            class="explore__section-img"
            :src="require('@/assets/images/objectifs.jpg')"
            alt=""
          />
          <!-- <img
            class="explore__section-img explore__section-img--small"
            src="https://images.unsplash.com/photo-1591696205602-2f950c417cb9?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=400&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTY0NzQ1MzE5Ng&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=400"
            alt=""
          /> -->
        </div>
      </div>
    </div>
    <div class="explore__section">
      <div class="explore__section-container">
        <div class="explore__section-content">
          <h2 class="explore__section-heading">Approche &#38; Vision</h2>
          <p class="explore__section-text">
            Notre approche est basée sur la facilité d’accès à l’information
            budgétaire et financière, pour stimuler le débat et enrichir les
            actions de plaidoyer. Rapprocher les commissions parlementaires de
            la société civile dans le but de diffuser/ formuler les attentes des
            citoyens et faire connaitre les actions prises par les
            parlementaires. Faciliter l’utilisation de l’information au moment
            opportun.
          </p>
        </div>
        <div class="explore__section-img-wrapper">
          <img
            class="explore__section-img"
            :src="require('@/assets/images/approche.jpg')"
            alt=""
          />
          <!-- <img
            class="explore__section-img explore__section-img--small explore__section-img--top"
            src="https://images.unsplash.com/photo-1571666521805-f5e8423aba9d?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=400&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTY1MDgwODA3MA&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=400"
            alt=""
          /> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.explore {
  &__section {
    @apply py-8 lg:py-12;
    &-container {
      @apply container mx-auto px-4
      flex flex-col gap-8
      lg:flex-row lg:gap-15 lg:items-center;
    }
    &-content {
      @apply flex-1;
    }
    &-heading {
      @apply text-lg font-bold
      mb-4
      lg:mb-10 lg:text-2xl
      relative before:w-20 before:h-1 before:bg-primary before:absolute before:left-0 before:-bottom-2;
      ;
    }
    &-text {
      @apply text-sm sm:text-base lg:text-lg max-w-2xl;
    }
    &-img {
      @apply rounded-3xl shadow-md w-[470px] h-[500px] object-cover;
      &--small {
        @apply absolute 
          -bottom-5 -left-6
          w-[200px] h-auto;
      }
      &--top{
        @apply -top-5 
      }
      &-wrapper {
        @apply relative;
      }
    }
    &-list {
      &-item {
        @apply my-2 relative pl-3
        text-sm sm:text-base lg:text-lg;
        &:before {
          content: "";
          @apply bg-primary/60 w-1 h-1 rounded-full absolute left-0 top-1;
        }
      }
    }
    &--swapped {
      @apply bg-white;
      .explore__section {
        &-content {
          @apply lg:order-2;
        }
        &-img-wrapper {
          @apply lg:order-1;
        }
      }
    }
  }
}
</style>